import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { HowToPlay } from "./pages/how-to-play";
import { Roadmap } from "./pages/roadmap";
import { Team } from "./pages/team";
import { ContactUs } from "./pages/contact-us";
import { Partnership } from "./pages/partnership";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/how-to-play"} exact component={HowToPlay} />
        <Route path={"/partnership"} exact component={Partnership} />
        <Route path={"/roadmap"} exact component={Roadmap} />
        <Route path={"/team"} exact component={Team} />
        <Route path={"/contact-us"} exact component={ContactUs} />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
