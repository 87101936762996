import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { errorHandling } from "./api";

export class AuthStore {
  async getOtpCode(phoneNum: string, countryCode: string) {
    const url = `${Configs.API_URL}/auth/get-otp`;
    try {
      const result: any = await axios.post(url, { phoneNum, countryCode });
      if (!result || !result["data"]) return result["message"];
      return true;
    } catch (e) {
      return generalError;
    }
  }

  async access(payload: any) {
    const url = `${Configs.API_URL}/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result["data"]) return generalError;
      if (!result["data"]["success"]) return generalError;

      const jwt = result["data"]["data"]["jwt"];
      localStorage.setItem("at-token", jwt);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_AUTH = "authStore";
