// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { AtGhNftStore, STORE_AT_GH_NFT } from "./at-gh-nft";
import { AuthStore, STORE_AUTH } from "./auth";
import { STORE_ROUTER, RouterStore, history } from "./router";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_AT_GH_NFT]: new AtGhNftStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useAtGhNftStore() {
  const { atGhNftStore } = useStores();
  return atGhNftStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
