import React from "react";
import { Container } from "src/components";

export const ContactUs = (props: any) => {
  return (
    <Container bg={null}>
      <div
        className="w-full flex flex-col md:flex-row p-[30px] md:p-[120px] bg-[#000]"
        id="contact-us"
        style={{ height: "calc(100vh)" }}
      >
        <div className="bg-white p-[30px] w-full h-full relative flex flex-col justify-between">
          <div>
            <h2 className="text-black">Get in Touch</h2>
            <p className="pt-[30px] text-black">
              If you want to get in touch please contact us at Instagram!
            </p>
          </div>

          <div>
            <p className="text-black text-[10px]">ABN 31 667 850 688</p>
            <p className="text-black text-[10px] pt-[15px]">
              Made in Melbourne © 2023 N3 Artiverse. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
