import React, { useEffect, useState } from "react";
import { Container } from "../../components";
import { Banner } from "./banner";

export const Home = (props: any) => {
  const [index, setIndex] = useState(Math.floor(Math.random() * (9 - 1) + 1));

  useEffect(() => {
    const home = document.getElementById("home");
    const idx: any = home?.getAttribute("idx");
    if (!idx) return;
    setIndex(idx);
  }, []);

  return (
    <Container index={index} setIndex={setIndex} bg={true}>
      <Banner index={index} setIndex={setIndex} />
    </Container>
  );
};
