import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";

export class AtGhNftStore {
  async getRemainingNfts() {
    const url = `${Configs.API_URL}/at-gh-nft/get-remaining`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result["data"]["success"]) return 0;
      return result["data"]["data"];
    } catch (e) {
      return generalError;
    }
  }

  async startMint(payload: any) {
    const url = `${Configs.API_URL}/at-gh-nft/start`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result["data"]["success"]) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }
}

export const STORE_AT_GH_NFT = "atGhNftStore";
