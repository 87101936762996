import React from "react";
import { Container } from "src/components";

export const HowToPlay = (props: any) => {
  const isMobile = window?.screen?.width < 740;

  return (
    <Container bg={null}>
      <div
        className="w-full flex flex-col md:flex-row p-[30px] md:p-[120px]"
        id="how-to-play"
        style={{ height: "calc(100vh)" }}
      >
        <div className="bg-white w-full h-full relative flex flex-col">
          <div
            id="video"
            className={`top-0 left-0 z-0 w-[100%] h-[100%] absolute`}
          >
            <video
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-full object-cover"
            >
              <source
                src={`/assets/how-to-play${isMobile ? "-mobile" : ""}.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </Container>
  );
};
