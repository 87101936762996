import React from "react";
import { Container } from "src/components";

export const Roadmap = (props: any) => {
  return (
    <Container bg={null}>
      <div
        className="w-full flex flex-col md:flex-row  p-[30px] md:p-[120px] bg-[#000]"
        id="roadmap"
        style={{ height: "calc(100vh)" }}
      >
        <div className="bg-white w-full h-full relative flex items-center justify-center">
          <img
            src="/assets/roadmap_new.jpg"
            alt="roadmap"
            className="max-w-[800px] w-[100%] object-cover bottom-[auto] top-[40px] md:top-[auto] md:bottom-[40px] left-0"
          />
        </div>
      </div>
    </Container>
  );
};
