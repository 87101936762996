import React from "react";
import { Container } from "src/components";

const data = [
  {
    name: "Ken Ho",
    title: "Founder, CEO & CTO",
    desc: "Ken has founded more than three companies and developed a stable user & revenue base. According to blockchain field, Ken is the Founder of Vulpes NFT & Nova3.Art. Ken has also worked in one of the Asia Pacific top3 loyalty company AsiaTop Loyalty Limited and Reverse as a CTO. And had worked in Hong Kong top 10 CEX ACDX as a Blockchain Lead.",
    avatar: "ken.jpeg",
  },
  {
    name: "ZEN FONG",
    title: "Co-Founder, COO",
    desc: "The current COO and Head of Metaverse Ecosystem of a leading Web3 company in Hong Kong, CanvasLand. Zen has amassed over a decade of experience in the blockchain digital asset space as an investor, researcher, and operator.",
    avatar: "zen.jpg",
  },
  {
    name: "Charlie Kong",
    title: "Co-Founder, Dupety CTO",
    desc: "Has worked in Hong Kong blockchain start up and able to provide profits to the projects. Charlie has a huge passion to Web3.0 technology and metaverse. And likes to read and share experience with technical developers when she has free time. Charlie is now working in one of the top 3 loyalty exchange platforms as a Lead Application Developer. And experience with game-changing projects e.g. Cryptocurrency, NFT and Metaverse.",
    avatar: "charlie.jpeg",
  },
  {
    name: "Lily Lei",
    title: "Co-Founder, CMO",
    desc: "Lily focuses in Educational field, and had worked in Hong Kong primary school as a Chinese, Science and Art teacher. And Lily is working as an Online techer now with more than 3,000+ lessons completed, and has been invited to take the promotion video for the platform. During the peak of NFT, Lily had worked as a Chief Marketing Officer at Vulpes NFT, and successfully hosted 20+ partnership events such as AMA, NFT Giveaways, and co-promotions.",
    avatar: "lily_new.jpeg",
  },
  {
    name: "J.Hue",
    title: "Co-Founder, Chief Designer",
    desc: "Founder of Angel Lab - a digital transformation company that focuses on creating breathtaking visual content. J.Hue is also the lead Art Director for multiple NFT projects - ElvaNFT and Odyssey NFT on the MultiversX Blockchain.",
    avatar: "hailee.jpeg",
  },
];

export const Team = (props: any) => {
  return (
    <Container bg={null}>
      <div
        className="w-full flex flex-col md:flex-row p-[30px] md:p-[120px] bg-[#000]"
        id="team"
        style={{ height: "calc(100vh)" }}
      >
        <div className="bg-white p-[30px] w-full h-full relative flex flex-col justify-between">
          <div className="gap-[20px] md:gap-[30px] grid grid-cols-1 md:grid-cols-2">
            {data.map((item, i) => {
              return (
                <div key={i} className="flex flex-row">
                  <img
                    src={`/assets/team/${item.avatar}`}
                    alt={`${item.name}-avatar`}
                    className="w-[125px] h-[125px] object-cover rounded-full flex-2"
                  />
                  <div className="flex flex-col px-[20px] py-[10px] flex-1 pr-[40px]">
                    <h2 className="text-black">{item.name}</h2>
                    <p className="text-black pt-[5px]">{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <p className="pt-[30px] text-black">And we welcome you to join us!</p>
        </div>
      </div>
    </Container>
  );
};
