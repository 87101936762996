import React from "react";

export const Footer = (props: any) => {
  const { setIdx, idx } = props;
  return (
    <div className="absoute bottom-0 left-0">
      {/* Next & Prev button */}
      <img
        src="/assets/next.png"
        alt="next"
        className={`${$btnStyle} !left-[90px] md:!left-[auto]`}
        onClick={() => (idx > 8 ? setIdx(1) : setIdx(idx + 1))}
      />
      <img
        src="/assets/prev.png"
        alt="prev"
        className={`${$btnStyle}  !right-[auto] md:!right-[90px]`}
        onClick={() => (idx > 1 ? setIdx(idx - 1) : setIdx(9))}
      />
    </div>
  );
};

const $btnStyle = `cursor-pointer absolute bottom-[auto] md:bottom-[30px] right-[auto] md:right-[30px] w-[40px] drop-shadow rounded-[50px]`;
