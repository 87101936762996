import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Menu } from "../menu";
import { SocialIcons } from "../social-icons";

export const Container = (props: any) => {
  const { children, index, bg } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div
      className={`block w-screen bg-cover bg-left ${
        bg ? "h-screen bg-[#101113]" : ""
      }`}
      style={{
        ...(index && { backgroundImage: `url('/assets/bg/${index}.png')` }),
      }}
    >
      {/* Menu */}
      <Menu bg={bg} />
      {children}

      {/* Floating Social Media Icons */}
      <SocialIcons />
    </div>
  );
};
