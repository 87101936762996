import React from "react";

export const SocialIcons = (props: any) => {
  const openUrl = (url: string) => {
    return window.open(url, "_blank");
  };

  return (
    <div className="fixed top-[50%] mt-[-60px] right-[30px] space-y-[15px] flex items-center justify-center w-[40px] flex-col">
      {/* Next & Prev button */}
      <img
        src="/assets/instagram.png"
        alt="ig"
        className={`${$btnStyle} `}
        onClick={() => openUrl("https://www.instagram.com/n3snap/")}
      />
      <img
        src="/assets/twitter.png"
        alt="twitter"
        className={`${$btnStyle} `}
        onClick={() => openUrl("https://twitter.com/CanvasLandWeb3")}
      />
      <img
        src="/assets/linkedin.png"
        alt="linkedin"
        className={`${$btnStyle} `}
        onClick={() => openUrl("https://www.linkedin.com/company/n3-artiverse")}
      />
    </div>
  );
};

const $btnStyle = `cursor-pointer w-[30px] drop-shadow rounded-[50px] drop-shadow-md`;
