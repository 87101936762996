import React, { useMemo } from "react";
import { Footer } from "src/components/footer";

export const Banner = (props: any) => {
  const { index, setIndex } = props;

  const title = useMemo(() => {
    if (!index) return;
    if (index === 1) return "Japanese General in Museum";
    if (index === 2) return "Camera of Flidners in Melbourne";
    if (index === 3) return "Kitsune in the street";
    if (index === 4) return "Plane to freedom";
    if (index === 5) return "Lovely Doggy in Melbourne";
    if (index === 6) return "Pinkish Sky";
    if (index === 7) return "A simple stairs";
    if (index === 8) return "Birds playing in rain";
    if (index === 9) return "The yellow wall";
    return "";
  }, [index]);

  return (
    <div
      className="w-full flex flex-col md:flex-row"
      id="banner"
      style={{ height: "calc(100vh - 120px)" }}
    >
      <div className="w-[100%] md:w-[25%] bg-[#101113] flex p-[30px] py-[10%] items-start md:items-end justify-center flex-col md:flex-row">
        <h1 className="rotated">Snap for Real</h1>
        <p className="rotated ml-[0px] md:ml-[30px] mt-[20px] md:mt-[0px]">
          In less than a minute, capture your most memorable moments and start
          earning $SNAP. #Snap-To-Earn
        </p>
      </div>

      <div className="bg-transparent p-[30px] w-full h-full relative">
        {/* Image Title */}
        <p className="rotated-invert absolute top-[30px] right-[30px]">
          {title}
        </p>
        {/* Download Button */}
        <div
          className={$downloadBtnStyle}
          onClick={() =>
            window.open(
              "https://apps.apple.com/hk/app/n3-snap/id6449212937?l=en"
            )
          }
        >
          <img src="/assets/iosbg.svg" className="h-[30px]" alt="ios" />
        </div>
        <div
          className={`${$downloadBtnStyle} !left-[190px]`}
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.n3snap"
            )
          }
        >
          <img
            src="/assets/googlePlay.svg"
            alt="android"
            className="h-[30px]"
          />
        </div>

        <Footer setIdx={setIndex} idx={index} />
      </div>
    </div>
  );
};

const $downloadBtnStyle = `cursor-pointer absolute bottom-[30px] left-[30px] drop-shadow bg-white py-[10px] px-[15px] rounded-[10px]`;
