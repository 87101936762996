import React from "react";
import { Container } from "src/components";

const data = [
  {
    logo: "Logo Pack_avatar blue.png",
    link: "https://twitter.com/vedao_official",
  },
  {
    logo: "halo.png",
    link: "https://twitter.com/HaloDotSocial",
  },
  {
    logo: "admeal.png",
    link: "https://twitter.com/Admeal_Official",
  },
];

export const Partnership = (props: any) => {
  return (
    <Container bg={null}>
      <div
        className="w-full flex flex-col md:flex-row p-[30px] md:p-[120px] bg-[#000]"
        style={{ height: "calc(100vh)" }}
      >
        <div className="bg-white p-[30px] w-full h-full relative flex flex-col justify-between">
          <div className="gap-[20px] md:gap-[30px] grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8">
            {data.map((item, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-row cursor-pointer"
                  onClick={() =>
                    window.open("https://twitter.com/vedao_official")
                  }
                >
                  <img
                    src={`/assets/partnership/${item.logo}`}
                    alt={`${item.logo}-logo`}
                    className="w-[75px] h-[75px] object-cover rounded-full flex-2 shadow-md"
                  />
                </div>
              );
            })}
          </div>
          <p className="pt-[30px] text-black">
            Let's parter with us to join our journey!
          </p>
        </div>
      </div>
    </Container>
  );
};
