import React, { useState } from "react";
import { history } from "src/stores";

export const Menu = (props: any) => {
  const { bg } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const renderMenu = () => {
    return (
      <div
        className={`transition-all duration-300 w-screen fixed top-0 left-0 bg-white p-[10%] ${
          openMenu
            ? "h-full z-[2000] overflow-hidden overflow-y-scroll"
            : "h-[0] invisible overflow-hidden"
        }`}
      >
        {/* Close icon */}
        <div className="bg-transparent absolute top-[40px] right-[30px] w-full flex justify-end">
          <img
            src="/assets/close.png"
            alt="close"
            className="cursor-pointer text-right w-[40px] drop-shadow rounded-[50px]"
            onClick={() => setOpenMenu(false)}
          />
        </div>
        {/* Menu Items */}
        <div className="flex flex-col space-y-[30px]">
          <h1 className={$menuTxtStyle} onClick={() => history.push("/")}>
            Home
          </h1>
          <h1
            className={$menuTxtStyle}
            onClick={() => history.push("how-to-play")}
          >
            How To Play
          </h1>
          <h1
            className={$menuTxtStyle}
            onClick={() => history.push("partnership")}
          >
            Partnership
          </h1>
          <h1 className={$menuTxtStyle} onClick={() => history.push("roadmap")}>
            Roadmap
          </h1>
          <h1 className={$menuTxtStyle} onClick={() => history.push("team")}>
            Team
          </h1>
          <h1
            className={$menuTxtStyle}
            onClick={() => history.push("contact-us")}
          >
            Contact Us
          </h1>
        </div>
      </div>
    );
  };

  return (
    <div className={`${bg ? "h-[110px]" : "fixed z-[9999]"} w-full `}>
      <div className={`w-full flex flex-row self-start items-center`}>
        {/* Logo */}
        {bg && (
          <div
            className={`w-[100%] md:w-[40%] ${
              bg ? "bg-[#101113]" : ""
            }  p-[30px] h-full flex flex-row items-center justify-center"`}
          >
            <img
              src="/assets/logo_without_bg.png"
              alt="logo"
              className="w-[50px] cursor-pointer"
              onClick={() => history.push("/")}
            />
            <h2 className="ml-[20px]">N3 Snap</h2>
          </div>
        )}
        {/* Menu icon */}
        <div className="fixed top-[40px] z-[200] right-[30px] bg-transparent w-full flex justify-end">
          <img
            src="/assets/menu.png"
            alt="menu"
            className="cursor-pointer text-right w-[40px] drop-shadow rounded-[50px]"
            onClick={() => setOpenMenu(true)}
          />
        </div>

        {/* Menu */}
        {renderMenu()}
      </div>
    </div>
  );
};

const $menuTxtStyle = "text-black cursor-pointer";
